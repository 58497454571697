.section-header {
  font-size: 1.25em;
  font-weight:  bold;
}

.filter {
  cursor:  pointer;
  font-size: 1.25em;
  font-weight:  bold;
  padding: .5em;
  border-bottom:  2px solid #eee;
  user-select: none;
  text-decoration: none;
}

.filter:hover {
}

.filter.selected, .filter.selected:hover {
  color: #1877F2;
  border-bottom:  2px solid #1877F2;
}

@media(max-width: 600px) {
  .filter {
    font-size: .95em;
  }
}

@media(max-width: 400px) {
  .filter {
    font-size: .8em;
  }
}

/*.filter.selected::before {
  content: ' ● ';
  color: #1877F2;
}
*/
.collection-link, .collection-link:visited {
  overflow: hidden;
  color: black;
  text-decoration: none;
}
.collection-link:visited {
  color: gray;
}
.collection-link:hover {
  color: #1877F2;
}

a.nav-link {
  text-decoration: none;
  color: gray;
}

.external-link, .external-link:hover, .external-link:visited {
  color: gray;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: no-wrap;
  font-size: .75em;
  text-align: left;
  text-decoration: none;
}