body {
  margin: 0;
  font-family: "VT323", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  font-size: 1.25em;
}

input, textarea, button {
  font-family: "VT323", monospace;
  font-size: 1em;
}

input:disabled {
  cursor: not-allowed;
}

input[type="text"], textarea {
  border-radius: 12px;
  box-sizing: border-box;
  padding: 1em;
  border: 1px solid #ccc;
}
/*button {
  cursor:  pointer;
  font-size: 1em;
  padding: .5em 1em;
  border: none;
  background-color: #3399FF;
  color: white;
  border-radius: 12px;
  font-weight: bold;
  box-shadow: 0 0 5px #ccc;
}*/
/*button:disabled {
  cursor: not-allowed;
  border: 1px solid transparent;
  background-color: #73D9FF;
  box-shadow: none;
}*/
body {
  padding: 0;
  margin: 0 auto;
}

a, a:visited, a:hover {
  color: inherit;
}

select {
  padding: .5em;
  font-family: "VT323", monospace;
  border-radius: 12px;
}

.flex-grow {
  -webkit-flex: 1 0 0;
  flex: 1 0 0;
}

.flex-shrink {
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
}
.flex {
  display: -webkit-flex !important;
  display: flex !important;
}

.PhoneInputInput {
  padding: .75em 1em;
  border-radius: 12px;
  border: 1px solid #ddd;
}

.toggle-switch-text {
  text-transform: uppercase;
  font-size: .8em;
  font-weight: bold;
  color: #999;
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 0px;
  margin: 0;
}
.toggle-switch-label:focus {
  outline: none;
}
.toggle-switch-label:focus > span {
  box-shadow: 0 0 2px 5px red;
}
.toggle-switch-label > span:focus {
  outline: none;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: attr(data-yes);
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #2F855A;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: attr(data-no);
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #444;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 0px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
.toggle-switch.small-switch {
  width: 40px;
}
.toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
  content: "";
  height: 20px;
  line-height: 20px;
}
.toggle-switch.small-switch .toggle-switch-switch {
  width: 16px;
  right: 20px;
  margin: 2px;
}

.toggle-switch {
  transform: scale(0.75);
}
